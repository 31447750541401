.sigCanvas {
    border: 1px solid #9e9e9e;
}

.button-box {
    border: none;
    background-color: #F594A0;
    border: medium none;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    margin: 0 5px;
}

.activeSubCategory {
    color: #F594A0;
}

.state-select {
    font-size: 14px;
    width: 100%;
    height: 45px;
    padding-left: 10px;
    color: #333;
    border: 2px solid #eceff8;
    background: #eceff8;
    box-shadow: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

@media screen and (max-width: 768px) {
    .hero-desktop-banner {
        display: none;
    }

}

@media screen and (min-width: 768px) {
    .hero-mobile-banner {
        display: none;
    }
}